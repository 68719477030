<template>
  <div class="background">
    <AiModels />
  </div>
</template>

<script>
import AiModels from "@/components/elements/dashboard/ai-models/AiModels";

export default {
  name: "DocumentManagementAiModels",

  components: {
    AiModels,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
