<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="aiModels"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="aiModelsPerPage"
          hide-default-footer
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Ai Models</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                @click="newAiModelROI()"
                color="primary"
                dark
                class="mb-2"                
              >
                New Ai Model (ROI)
              </v-btn>
              <v-dialog v-model="dialog" persistent max-width="600px">
                <template
                  v-if="$can('ai_model_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 ml-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Ai Model
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <!-- formFile -->
                      <v-form
                        v-show="useFiles && firstStep"
                        ref="formFile"
                        lazy-validation
                      >
                        <v-row>
                          <!-- Files -->
                          <v-col class="mt-n5" cols="12">
                            <input
                              type="file"
                              accept="image/*"
                              @change="previewImage"
                              class="mt-2"
                              id="my-file"
                            />

                            <v-card flat class="mt-2">
                              <p>Preview Here:</p>

                              <template v-if="preview">
                                <v-img :src="preview" width="100%" />

                                <p class="mb-0 mt-2">
                                  File Name: {{ image.name }}
                                </p>
                                <p class="mb-0">
                                  size: ({{
                                    (image.size / (1024 * 1024)).toFixed(2)
                                  }}MB)
                                </p>
                              </template>
                            </v-card>
                          </v-col>

                          <!-- Next -->
                          <v-col v-if="useFiles" class="mt-n3" cols="12">
                            <div class="d-flex justify-end">
                              <v-btn
                                class="btn btn-primary"
                                @click="validateFirstStep()"
                              >
                                Next
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-form>

                      <!-- form -->
                      <v-form
                        v-show="!useFiles || !firstStep"
                        ref="form"
                        lazy-validation
                      >
                        <v-row>
                          <!-- Name -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedAiModel.name"
                              :rules="nameRules"
                              label="Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Data -->
                          <v-col
                            v-for="(
                              keyValuePairObject, index
                            ) in keyValuePairsObject"
                            class="mt-n5"
                            cols="12"
                            :key="index"
                          >
                            <v-row>
                              <!-- Key -->
                              <v-col cols="7">
                                <v-text-field
                                  v-model="keyValuePairObject.value"
                                  :rules="valueRules"
                                  required
                                >
                                  <template v-slot:label>
                                    <p class="body-1">
                                      Key <span class="red--text">*</span>
                                    </p>
                                  </template>
                                </v-text-field>
                              </v-col>

                              <!-- Type -->
                              <v-col cols="4">
                                <v-select
                                  v-model="keyValuePairObject.type"
                                  :items="keyValuePairTypes"
                                  :rules="typeRules"
                                  attach
                                  required
                                >
                                  <template v-slot:label>
                                    <p class="body-1">
                                      Type <span class="red--text">*</span>
                                    </p>
                                  </template>
                                </v-select>
                              </v-col>

                              <!-- Delete -->
                              <v-col cols="1">
                                <v-icon
                                  small
                                  color="red"
                                  class="mb-n12"
                                  @click="removeKeyValuePair(index)"
                                >
                                  mdi-delete
                                </v-icon>
                              </v-col>

                              <!-- Max Length -->
                              <v-col
                                v-if="keyValuePairObject.type == 'Numbers Only'"
                                cols="12"
                                class="mt-n6"
                              >
                                <v-text-field
                                  v-model="keyValuePairObject.maxLength"
                                  :rules="maxLengthRules"
                                  label="Max Length"
                                  type="number"
                                  required
                                >
                                  <template v-slot:label>
                                    <p class="body-1">
                                      Max Length
                                      <span class="red--text">*</span>
                                    </p>
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>

                          <!-- Add Key Value Pair -->
                          <v-col class="mt-n10 mb-4" cols="12">
                            <div class="d-flex justify-end">
                              <div 
                                v-if="Object.keys(editedAiModel.key_value_pairs_object).length > 0 || formTitle === 'New Ai Model'"
                                @click="addKeyValuePair()"
                              >
                                <v-icon color="green" class="mb-n12">
                                  mdi-playlist-plus
                                </v-icon>
                              </div>
                            </div>
                          </v-col>

                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedAiModel.line_separator"
                              :rules="lineSeparatorRules"
                              label="Line Separator"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Line Separator
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Companies -->
                          <v-col
                            v-if="$can('super_admin')"
                            class="mt-n5"
                            cols="12"
                          >
                            <v-select
                              v-model="companyName"
                              :items="companyNamesList"
                              :rules="companyNameRules"
                              attach
                              label="Companies"
                            >
                              <template v-slot:label>
                                <p class="body-1">Company Name</p>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- Back -->
                          <v-col v-if="useFiles" class="mt-n3" cols="12">
                            <div class="d-flex justify-end">
                              <v-btn
                                class="btn btn-primary"
                                @click="firstStep = true"
                              >
                                Back
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogRetrain" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure you want to retrain
                    {{ aiModel.name }}?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="btn btn-primary"
                      @click="dialogRetrain = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary"
                      @click="retrainApiAiModel(aiModel.id)"
                    >
                      OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this Ai
                    Model?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="btn btn-primary" @click="deleteAiModelConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          
          <!-- View -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.view="{ item }">
            <v-btn
              v-if="Object.keys(item.key_value_pairs_object).length === 0"
              color="primary"
              :to="{
                name: 'DashboardAiModelView',
                params: { id: item.id },
              }"
            >
              View {{ item.view }}
              <v-icon size="36" right> mdi-dots-vertical </v-icon></v-btn
            >
          </template>

          <!-- active -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.active="{ item }">
            <v-chip
              v-if="item.active"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Yes
            </v-chip>

            <v-chip v-else class="ma-2" color="red" text-color="white">
              No
            </v-chip>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- retrain -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.retrain="{ item }">
            <v-btn v-if="$can('ai_model_create')" @click="retrainAiModel(item)" color="primary" dark>
              Retrain
            </v-btn>
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- expanded -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-3">
              <div v-if="$can('super_admin')">
                <span> Url: {{ item.url }} </span>
                <br />
              </div>
              <div>
                <strong> Key Value Pairs </strong>
              </div>
              <div
                v-for="(key_value_pair, index) in item.key_value_pairs_object"
                :key="index"
              >
                <strong>
                  {{ $helpers.camelToTitle(key_value_pair[0]) }} :
                </strong>
                {{ $helpers.toTitleCase(key_value_pair[1]) }}
                <!-- <div
                  v-if="
                    $helpers.toTitleCase(key_value_pair[1]) == 'Numbers Only' &&
                    key_value_pair.length >= 3
                  "
                >
                  <strong>
                    Max Length {{ $helpers.toTitleCase(key_value_pair[2]) }}
                  </strong>
                </div> -->
              </div>
            </td>
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('ai_model_update')"
              small
              class="mr-2"
              @click="editAiModel(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('ai_model_delete')"
              small
              @click="deleteAiModel(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Ai Models per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- AddNewAiModelWithROIDialog -->
      <AddNewAiModelWithROIDialog />

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import AddNewAiModelWithROIDialog from "@/components/elements/dashboard/ai-models/AddNewAiModelWithROIDialog.vue";

export default {
  name: "DocumentManagementAiModels",

  components: {
    AddNewAiModelWithROIDialog,
  },

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "", sortable: false, value: "view" },
        { text: "Id", align: "start", value: "id" },
        { text: "Name", value: "name" },
        { text: "Status", value: "status" },
        { text: "Line Separator", value: "line_separator" },
        { text: "Retrain", value: "retrain" },
        { text: "Company", value: "company_id" },
        { text: "File Name", value: "file_name" },
        { text: "Error Message", value: "error_message" },
        // { text: "File Location", value: "file_location" },
        // { text: "Url", value: "url" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      expanded: [],
      singleExpand: false,

      aiModels: [],

      companies: [],
      companyName: "",
      companyNamesList: [],

      editedIndex: -1,
      editedAiModel: {
        id: 0,
        name: "",
        line_separator: 19,
        company_id: 0,
        key_value_pairs_object: {},
        created_at: "",
        updated_at: "",
      },
      defaultAiModel: {
        id: 0,
        name: "",
        line_separator: 19,
        company_id: 0,
        key_value_pairs_object: {},
        created_at: "",
        updated_at: "",
      },

      // name
      nameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // lineSeparator
      lineSeparatorRules: [
        (v) => !!v || "Required",
        (v) => v >= 0 || "Line Separator should be 0 or greater",
      ],

      // companyName
      companyNameRules: [],
      keyValuePairTypes: [
        "Numbers Only",
        "Name Pattern",
        "Text Only",
        "Sa Id Number",
      ],

      keyValuePairsObject: [
        {
          value: "",
          type: "",
          maxLength: "",
        },
      ],

      // valueRules
      valueRules: [(v) => !!v || "Required"],

      // typeRules
      typeRules: [(v) => !!v || "Required"],

      // maxLengthRules
      maxLengthRules: [(v) => !!v || "Required"],

      preview: null,
      image: null,

      // files
      files: null,
      filesRules: [(v) => !!v || "Required"],

      multiple: false,
      useFiles: true,
      firstStep: true,

      page: 1,
      aiModelsPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],

      // retrain
      dialogRetrain: false,
      aiModel: {},
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1 ? "New Ai Model" : "Edit Ai Model";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedAiModels();
    },

    // aiModelsPerPage
    aiModelsPerPage() {
      this.getApiPagedAiModels();
    },

    // perPageChoice
    perPageChoice(val) {
      this.aiModelsPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    this.$store.commit("aiModel/setAddAiModelDialog", false);

    if (!this.$can("ai_model_access")) {
      this.$router.push({ name: "DashboardInbox" });
    }

    if (!this.$can("super_admin")) {
      this.headers = [
        { text: "Id", align: "start", value: "id" },
        { text: "Name", value: "name" },
        { text: "Status", value: "status" },
        { text: "Line Separator", value: "line_separator" },
        { text: "Retrain", value: "retrain" },
        { text: "Error Message", value: "error_message" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    }

    // this.getApiAiModels();
    this.getApiPagedAiModels();

    if (this.$can("super_admin")) {
      this.getApiCompanies();
    }
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
      resetAiModelState: "aiModel/resetState",

      setDialogAiModel: "aiModel/setDialogAiModel",
      setTotalTime: "aiModel/setTotalTime",
      setAiModelId: "aiModel/setAiModelId",
      setTimeLeft: "aiModel/setTimeLeft",
      setTime: "aiModel/setTime",

      setCountDown: "aiModel/setCountDown",
    }),

    // newAiModelROI
    newAiModelROI(){
      this.$store.commit("aiModel/setAddAiModelDialog", true);
    },

    // addKeyValuePair
    addKeyValuePair() {
      this.keyValuePairsObject.push({
        value: "",
        type: "",
        maxLength: "",
      });
    },

    // removeKeyValuePair
    removeKeyValuePair(index) {
      this.keyValuePairsObject.splice(index, 1);
    },

    // previewImage
    previewImage: function (event) {
      this.preview = null;

      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }

      event.target.value = null;
    },

    // validateForm
    validateForm() {
      if (this.useFiles && this.image == null) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please Upload Image",
        });
      } else {
        this.$refs.form.validate();

        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            this.updateApiAiModel();
          } else {
            if (this.useFiles) {
              this.createApiAiModelWithImage();
            } else {
              this.createApiAiModel();
            }
          }
        }
      }
    },

    // validateFirstStep
    validateFirstStep() {
      this.firstStep = false;
    },

    // editAiModel
    editAiModel(aiModel) {
      this.editedIndex = this.aiModels.indexOf(aiModel);
      this.editedAiModel = Object.assign({}, aiModel);
      this.useFiles = false;
      this.dialog = true;

      if (this.editedIndex != -1) {
        this.companyName = this.$helpers.getNameByIdFromList(
          this.companies,
          aiModel.company_id
        );
      }

      this.keyValuePairsObject = [];

      for (var key in aiModel.key_value_pairs_object) {
        var value = aiModel.key_value_pairs_object[key];

        if (value.length >= 3) {
          this.keyValuePairsObject.push({
            value: this.$helpers.camelToTitle(value[0]),
            type: this.$helpers.toTitleCase(value[1]),
            maxLength: value[2],
          });
        } else {
          this.keyValuePairsObject.push({
            value: this.$helpers.camelToTitle(value[0]),
            type: this.$helpers.toTitleCase(value[1]),
            maxLength: "",
          });
        }
      }
    },

    // deleteAiModel
    deleteAiModel(aiModel) {
      this.editedIndex = this.aiModels.indexOf(aiModel);
      this.editedAiModel = Object.assign({}, aiModel);
      this.dialogDelete = true;
    },

    // deleteAiModelConfirm
    deleteAiModelConfirm() {
      this.deleteApiAiModel();
    },

    // close
    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedAiModel = Object.assign({}, this.defaultAiModel);
        this.editedIndex = -1;
        this.companyName = "";
        this.keyValuePairsObject = [
          {
            value: "",
            type: "",
            maxLength: "",
          },
        ];

        this.firstStep = true;
        this.useFiles = true;
        this.image = null;
        this.preview = null;
      });
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedAiModel = Object.assign({}, this.defaultAiModel);
        this.editedIndex = -1;
      });
    },

    // retrainAiModel
    retrainAiModel(item) {
      this.aiModel = item;

      this.dialogRetrain = true;
    },

    // save
    save() {
      this.validateForm();
    },

    // getApiAiModels
    async getApiAiModels() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("ai-models");

      if (res.status == 200) {
        this.aiModels = res.data;
      }

      this.overlay = false;
    },

    // getApiPagedAiModels
    async getApiPagedAiModels() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `ai-models/paged?page_no=${this.page}&page_size=${this.aiModelsPerPage}`
      );

      if (res.status == 200) {
        this.aiModels = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // createApiAiModel
    async createApiAiModel() {
      this.overlay = true;

      // keyValuePairsObject
      if (this.keyValuePairsObject.length > 0) {
        var reqData = {
          name: this.editedAiModel.name,
          line_separator: this.editedAiModel.line_separator,
          company_id: this.auth.user.company_id,
        };

        if (this.companyName != "") {
          var id = this.$helpers.getIdByNameFromList(
            this.companies,
            this.companyName
          );

          if (id > 0) {
            reqData.company_id = id;
          }
        }

        var keyValuePairs = {};
        var indexLabel = 1;

        this.keyValuePairsObject.forEach((keyValuePair) => {
          if (keyValuePair.type == "Numbers Only") {
            keyValuePairs["label_" + indexLabel] = [
              this.$helpers.toSnakeCase(keyValuePair.value),
              this.$helpers.toSnakeCase(keyValuePair.type),
              parseInt(keyValuePair.maxLength),
            ];
          } else {
            keyValuePairs["label_" + indexLabel] = [
              this.$helpers.toSnakeCase(keyValuePair.value),
              this.$helpers.toSnakeCase(keyValuePair.type),
            ];
          }

          indexLabel++;
        });

        reqData.key_value_pairs = keyValuePairs;

        const res = await this.$helpers.createApiData(
          "ai-models",
          reqData,
          "Ai Model Created Successfully!"
        );

        if (res.status == 200) {
          this.aiModels.push(res.data);

          this.close();
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Provide Atleast One Key Value Pairs",
        });
      }

      this.overlay = false;
    },

    // createApiAiModelWithImage
    async createApiAiModelWithImage() {
      this.overlay = true;

      // keyValuePairsObject
      if (this.keyValuePairsObject.length > 0) {
        var reqData = {
          name: this.editedAiModel.name,
          line_separator: this.editedAiModel.line_separator,
          company_id: this.auth.user.company_id,
        };

        if (this.companyName != "") {
          var id = this.$helpers.getIdByNameFromList(
            this.companies,
            this.companyName
          );

          if (id > 0) {
            reqData.company_id = id;
          }
        }

        var keyValuePairs = {};
        var indexLabel = 1;

        this.keyValuePairsObject.forEach((keyValuePair) => {
          if (keyValuePair.type == "Numbers Only") {
            keyValuePairs["label_" + indexLabel] = [
              this.$helpers.toSnakeCase(keyValuePair.value),
              this.$helpers.toSnakeCase(keyValuePair.type),
              parseInt(keyValuePair.maxLength),
            ];
          } else {
            keyValuePairs["label_" + indexLabel] = [
              this.$helpers.toSnakeCase(keyValuePair.value),
              this.$helpers.toSnakeCase(keyValuePair.type),
            ];
          }

          indexLabel++;
        });

        reqData.key_value_pairs = keyValuePairs;

        const res = await this.$helpers.createApiData(
          "ai-models",
          reqData,
          "Ai Model Created Successfully!"
        );

        if (res.status == 200) {
          this.aiModels.push(res.data);

          var formData = new FormData();

          // files
          formData.append("files[" + 0 + "]", this.image);

          const res2 = await this.$helpers.createApiFormData(
            `ai-models/add-image-to-ai-model-and-train/${res.data.id}`,
            formData,
            "Image Uploaded Successfully!"
          );

          if (res2.status == 200) {
            // setCountDown
            this.setCountDown(15 * 60);

            // setTimeLeft
            this.setTimeLeft(15 * 60 * 1000);

            // setDialogAiModel
            this.setDialogAiModel(true);

            // setAiModelId
            this.setAiModelId(res.data.id);
          }

          this.close();
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Provide Atleast One Key Value Pairs",
        });
      }

      this.overlay = false;
    },

    // retrainApiAiModel
    async retrainApiAiModel(id) {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `/ai-models/retrain-ai-model/${id}`,
        {},
        "Started Retrain Successfully!"
      );

      if (res.status == 200) {
        let aiModel = this.aiModels.find((aiModel) => aiModel.id == id);

        let index = this.aiModels.indexOf(aiModel);

        Object.assign(this.aiModels[index], res.data);

        // setCountDown
        this.setCountDown(15 * 60);

        // setTimeLeft
        this.setTimeLeft(15 * 60 * 1000);

        // setDialogAiModel
        this.setDialogAiModel(true);

        // setAiModelId
        this.setAiModelId(res.data.id);

        this.dialogRetrain = false;
      }

      this.close();

      this.overlay = false;
    },

    // updateApiAiModel
    async updateApiAiModel() {
      this.overlay = true;

      // keyValuePairsObject
      if (this.keyValuePairsObject.length > 0) {
        var reqData = {
          id: this.editedAiModel.id,
          name: this.editedAiModel.name,
          line_separator: this.editedAiModel.line_separator,
          company_id: this.auth.user.company_id,
        };

        if (this.companyName != "") {
          var id = this.$helpers.getIdByNameFromList(
            this.companies,
            this.companyName
          );

          if (id > 0) {
            reqData.company_id = id;
          }
        }

        var keyValuePairs = {};
        var indexLabel = 1;

        this.keyValuePairsObject.forEach((keyValuePair) => {
          if (keyValuePair.type == "Numbers Only") {
            keyValuePairs["label_" + indexLabel] = [
              this.$helpers.toSnakeCase(keyValuePair.value),
              this.$helpers.toSnakeCase(keyValuePair.type),
              parseInt(keyValuePair.maxLength),
            ];
          } else {
            keyValuePairs["label_" + indexLabel] = [
              this.$helpers.toSnakeCase(keyValuePair.value),
              this.$helpers.toSnakeCase(keyValuePair.type),
            ];
          }

          indexLabel++;
        });

        reqData.key_value_pairs = keyValuePairs;

        const res = await this.$helpers.updateApiData(
          `ai-models/${this.editedAiModel.id}`,
          reqData,
          "Ai Model Updated Successfully!"
        );

        if (res.status == 200) {
          Object.assign(this.aiModels[this.editedIndex], res.data);

          this.close();
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Provide Atleast One Key Value Pairs",
        });
      }

      this.overlay = false;
    },

    // deleteApiAiModel
    async deleteApiAiModel() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `ai-models/${this.editedAiModel.id}`,
        "Ai Model Deleted Successfully!"
      );

      if (res.status == 200) {
        this.aiModels.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // Get Api Companies
    async getApiCompanies() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("companies");

      if (res.status == 200) {
        this.companies = res.data;

        this.companyNamesList = this.$helpers.getNamesFromList(this.companies);
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
