<template>
    <div>
        <v-dialog v-model="addAiModelDialog" persistent max-width="600px">
            <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                <!-- formFile -->
                <v-form
                    v-show="useFiles && firstStep"
                    ref="formFile"
                    lazy-validation
                >
                    <v-row>
                    <!-- Files -->
                    <v-col class="mt-n5" cols="12">
                        <input
                        type="file"
                        accept="image/*"
                        @change="previewImage"
                        class="mt-2"
                        id="my-file"
                        />

                        <v-card flat class="mt-2">
                        <p>Preview Here:</p>

                        <template v-if="preview">
                            <v-img :src="preview" width="100%" />

                            <p class="mb-0 mt-2">
                            File Name: {{ image.name }}
                            </p>
                            <p class="mb-0">
                            size: ({{
                                (image.size / (1024 * 1024)).toFixed(2)
                            }}MB)
                            </p>
                        </template>
                        </v-card>
                    </v-col>

                    <!-- Next -->
                    <v-col v-if="useFiles" class="mt-n3" cols="12">
                        <div class="d-flex justify-end">
                        <v-btn
                            class="btn btn-primary"
                            @click="validateFirstStep()"
                        >
                            Next
                        </v-btn>
                        </div>
                    </v-col>
                    </v-row>
                </v-form>

                <!-- form -->
                <v-form
                    v-show="!useFiles || !firstStep"
                    ref="form"
                    lazy-validation
                >
                    <v-row>
                    <!-- Name -->
                    <v-col class="mt-n5" cols="12">
                        <v-text-field
                        v-model="editedAiModel.name"
                        :rules="nameRules"
                        label="Name"
                        required
                        >
                        <template v-slot:label>
                            <p class="body-1">
                            Name <span class="red--text">*</span>
                            </p>
                        </template>
                        </v-text-field>
                    </v-col>

                    <!-- Data -->
                    <v-col
                        v-for="(
                        keyValuePairObject, index
                        ) in keyValuePairsObject"
                        class="mt-n5"
                        cols="12"
                        :key="index"
                    >
                        <v-row>
                        <!-- Key -->
                        <v-col cols="7">
                            <v-text-field
                            v-model="keyValuePairObject.value"
                            :rules="valueRules"
                            required
                            >
                            <template v-slot:label>
                                <p class="body-1">
                                Key <span class="red--text">*</span>
                                </p>
                            </template>
                            </v-text-field>
                        </v-col>

                        <!-- Type -->
                        <v-col cols="4">
                            <v-select
                            v-model="keyValuePairObject.type"
                            :items="keyValuePairTypes"
                            :rules="typeRules"
                            attach
                            required
                            >
                            <template v-slot:label>
                                <p class="body-1">
                                Type <span class="red--text">*</span>
                                </p>
                            </template>
                            </v-select>
                        </v-col>

                        <!-- Delete -->
                        <v-col cols="1">
                            <v-icon
                            small
                            color="red"
                            class="mb-n12"
                            @click="removeKeyValuePair(index)"
                            >
                            mdi-delete
                            </v-icon>
                        </v-col>

                        <!-- Max Length -->
                        <v-col
                            v-if="keyValuePairObject.type == 'Numbers Only'"
                            cols="12"
                            class="mt-n6"
                        >
                            <v-text-field
                            v-model="keyValuePairObject.maxLength"
                            :rules="maxLengthRules"
                            label="Max Length"
                            type="number"
                            required
                            >
                            <template v-slot:label>
                                <p class="body-1">
                                Max Length
                                <span class="red--text">*</span>
                                </p>
                            </template>
                            </v-text-field>
                        </v-col>
                        </v-row>
                    </v-col>

                    <!-- Add Key Value Pair -->
                    <v-col v-if="false" class="mt-n10 mb-4" cols="12">
                        <div class="d-flex justify-end">
                        <div @click="addKeyValuePair()">
                            <v-icon color="green" class="mb-n12">
                            mdi-playlist-plus
                            </v-icon>
                        </div>
                        </div>
                    </v-col>

                    <v-col class="mt-n5" cols="12">
                        <v-text-field
                        v-model="editedAiModel.line_separator"
                        :rules="lineSeparatorRules"
                        label="Line Separator"
                        type="number"
                        required
                        >
                        <template v-slot:label>
                            <p class="body-1">
                            Line Separator
                            <span class="red--text">*</span>
                            </p>
                        </template>
                        </v-text-field>
                    </v-col>

                    <!-- Companies -->
                    <v-col
                        v-if="$can('super_admin')"
                        class="mt-n5"
                        cols="12"
                    >
                        <v-select
                        v-model="companyName"
                        :items="companyNamesList"
                        :rules="companyNameRules"
                        attach
                        label="Companies"
                        >
                        <template v-slot:label>
                            <p class="body-1">Company Name</p>
                        </template>
                        </v-select>
                    </v-col>

                    <!-- Back -->
                    <v-col v-if="useFiles" class="mt-n3" cols="12">
                        <div class="d-flex justify-end">
                        <v-btn
                            class="btn btn-primary"
                            @click="firstStep = true"
                        >
                            Back
                        </v-btn>
                        </div>
                    </v-col>
                    </v-row>
                </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="btn btn-primary" @click="close()">
                Cancel
                </v-btn>
                <v-btn color="btn btn-primary" @click="save()"> Save </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>        

        <!-- Overlay -->
        <v-overlay :value="overlay" z-index="1000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        
    </div>
</template>
  
<script>
import { mapMutations, mapState } from "vuex";

export default {
    name: 'DocumentManagementAddNewAiModelWithROIDialog',

    props: {
        redirect: {
            default: "",
            type: String,
        },
    },

    data() {
    return {
        overlay: false,

        dialog: false,

        aiModels: [],

        companies: [],
        companyName: "",
        companyNamesList: [],

        editedIndex: -1,
        editedAiModel: {
        id: 0,
        name: "",
        line_separator: 19,
        company_id: 0,
        key_value_pairs_object: {},
        created_at: "",
        updated_at: "",
        },
        defaultAiModel: {
        id: 0,
        name: "",
        line_separator: 19,
        company_id: 0,
        key_value_pairs_object: {},
        created_at: "",
        updated_at: "",
        },

        // name
        nameRules: [
            (v) => !!v || "Required",
            (v) =>
                /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
                "Only english letters, apostrophes and hyphens are allowed",
            (v) => v.length >= 3 || "Can not be less than 3 characters",
            (v) => v.length <= 100 || "Can not be more than 100 characters",
        ],

        // lineSeparator
        lineSeparatorRules: [
        (v) => !!v || "Required",
        (v) => v >= 0 || "Line Separator should be 0 or greater",
        ],

        // companyName
        companyNameRules: [],
        keyValuePairTypes: [
        "Numbers Only",
        "Name Pattern",
        "Text Only",
        "Sa Id Number",
        ],

        keyValuePairsObject: [
        // {
        //   value: "",
        //   type: "",
        //   maxLength: "",
        // },
        ],

        // valueRules
        valueRules: [(v) => !!v || "Required"],

        // typeRules
        typeRules: [(v) => !!v || "Required"],

        // maxLengthRules
        maxLengthRules: [(v) => !!v || "Required"],

        preview: null,
        image: null,

        // files
        files: null,
        filesRules: [(v) => !!v || "Required"],

        multiple: false,
        useFiles: true,
        firstStep: true,

        page: 1,
        aiModelsPerPage: 100,
        totalRecords: 0,
        pageCount: 0,
        perPageChoice: { text: "100 records/page", value: 100 },
        perPageChoices: [
            { text: "5 records/page", value: 5 },
            { text: "25 records/page", value: 25 },
            { text: "50 records/page", value: 50 },
            { text: "100 records/page", value: 100 },
            { text: "150 records/page", value: 150 },
            { text: "200 records/page", value: 200 },
        ],

        // retrain
        dialogRetrain: false,
        aiModel: {},
    };
    },

    computed: {
    ...mapState({ auth: "auth" }),

    // addAiModelDialog
    addAiModelDialog(){
        // getAddAiModelDialog
      return this.$store.getters['aiModel/getAddAiModelDialog'];
    },

    // formTitle
    formTitle() {
        return this.editedIndex === -1 ? "New Ai Model (ROI)" : "Edit Ai Model";
    },
    },

    watch: {
    // dialog
    dialog(val) {
        val || this.close();
    },

    // page
    page() {
        this.getApiPagedAiModels();
    },

    // aiModelsPerPage
    aiModelsPerPage() {
        this.getApiPagedAiModels();
    },

    // perPageChoice
    perPageChoice(val) {
        this.aiModelsPerPage = val.value;
        this.page = 1;
    },
    },

    created() {
        if (!this.$can("ai_model_access")) {
            this.$router.push({ name: "DashboardInbox" });
        }

        // this.getApiAiModels();
        this.getApiPagedAiModels();

        if (this.$can("super_admin")) {
            this.getApiCompanies();
        }
    },

    methods: {
    ...mapMutations({
        resetAuthState: "auth/resetState",
        resetAiModelState: "aiModel/resetState",

        setDialogAiModel: "aiModel/setDialogAiModel",
        setTotalTime: "aiModel/setTotalTime",
        setAiModelId: "aiModel/setAiModelId",
        setTimeLeft: "aiModel/setTimeLeft",
        setTime: "aiModel/setTime",

        setCountDown: "aiModel/setCountDown",
    }),

    // addKeyValuePair
    addKeyValuePair() {
        this.keyValuePairsObject.push({
        value: "",
        type: "",
        maxLength: "",
        });
    },

    // removeKeyValuePair
    removeKeyValuePair(index) {
        this.keyValuePairsObject.splice(index, 1);
    },

    // previewImage
    previewImage: function (event) {
        this.preview = null;

        var input = event.target;
        if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
            this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
        }

        event.target.value = null;
    },

    // validateForm
    validateForm() {
        if (this.useFiles && this.image == null) {
        this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Please Upload Image",
        });
        } else {
        this.$refs.form.validate();

        if (this.$refs.form.validate()) {
            if (this.editedIndex > -1) {
            this.updateApiAiModel();
            } else {
            if (this.useFiles) {
                this.createApiAiModelWithImage();
            } else {
                this.createApiAiModel();
            }
            }
        }
        }
    },

    // validateFirstStep
    validateFirstStep() {
        this.firstStep = false;
    },

    // editAiModel
    editAiModel(aiModel) {
        this.editedIndex = this.aiModels.indexOf(aiModel);
        this.editedAiModel = Object.assign({}, aiModel);
        this.useFiles = false;
        this.$store.commit("aiModel/setAddAiModelDialog", false);

        if (this.editedIndex != -1) {
        this.companyName = this.$helpers.getNameByIdFromList(
            this.companies,
            aiModel.company_id
        );
        }

        this.keyValuePairsObject = [];

        for (var key in aiModel.key_value_pairs_object) {
        var value = aiModel.key_value_pairs_object[key];

        if (value.length >= 3) {
            this.keyValuePairsObject.push({
            value: this.$helpers.camelToTitle(value[0]),
            type: this.$helpers.toTitleCase(value[1]),
            maxLength: value[2],
            });
        } else {
            this.keyValuePairsObject.push({
            value: this.$helpers.camelToTitle(value[0]),
            type: this.$helpers.toTitleCase(value[1]),
            maxLength: "",
            });
        }
        }
    },

    // deleteAiModelConfirm
    deleteAiModelConfirm() {
        this.deleteApiAiModel();
    },

    // close
    close() {
        this.$store.commit("aiModel/setAddAiModelDialog", false);

        this.$nextTick(() => {
        this.editedAiModel = Object.assign({}, this.defaultAiModel);
        this.editedIndex = -1;
        this.companyName = "";
        this.keyValuePairsObject = [
            // {
            // value: "",
            // type: "",
            // maxLength: "",
            // },
        ];

        this.firstStep = true;
        this.useFiles = true;
        this.image = null;
        this.preview = null;
        });
    },

    // retrainAiModel
    retrainAiModel(item) {
        this.aiModel = item;

        this.dialogRetrain = true;
    },

    // save
    save() {
        this.validateForm();
    },

    // getApiAiModels
    async getApiAiModels() {
        this.overlay = true;

        const res = await this.$helpers.getApiData("ai-models");

        if (res.status == 200) {
            this.aiModels = res.data;
        }

        this.overlay = false;
    },

    // getApiPagedAiModels
    async getApiPagedAiModels() {
        this.overlay = true;

        const res = await this.$helpers.getApiData(
        `ai-models/paged?page_no=${this.page}&page_size=${this.aiModelsPerPage}`
        );

        if (res.status == 200) {
        this.aiModels = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
        }

        this.overlay = false;
    },

    // createApiAiModel
    async createApiAiModel() {
        this.overlay = true;

        // keyValuePairsObject
        if (this.keyValuePairsObject.length > 0 || this.overlay) {
        var reqData = {
            name: this.editedAiModel.name,
            line_separator: this.editedAiModel.line_separator,
            company_id: this.auth.user.company_id,
        };

        if (this.companyName != "") {
            var id = this.$helpers.getIdByNameFromList(
            this.companies,
            this.companyName
            );

            if (id > 0) {
            reqData.company_id = id;
            }
        }

        var keyValuePairs = {};
        var indexLabel = 1;

        this.keyValuePairsObject.forEach((keyValuePair) => {
            if (keyValuePair.type == "Numbers Only") {
            keyValuePairs["label_" + indexLabel] = [
                this.$helpers.toSnakeCase(keyValuePair.value),
                this.$helpers.toSnakeCase(keyValuePair.type),
                parseInt(keyValuePair.maxLength),
            ];
            } else {
            keyValuePairs["label_" + indexLabel] = [
                this.$helpers.toSnakeCase(keyValuePair.value),
                this.$helpers.toSnakeCase(keyValuePair.type),
            ];
            }

            indexLabel++;
        });

        reqData.key_value_pairs = keyValuePairs;

        const res = await this.$helpers.createApiData(
            "ai-models",
            reqData,
            "Ai Model Created Successfully!"
        );

        if (res.status == 200) {
            this.aiModels.push(res.data);

            this.close();
        }
        } else {
        this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Provide Atleast One Key Value Pairs",
        });
        }

        this.overlay = false;
    },

    // createApiAiModelWithImage
    async createApiAiModelWithImage() {
        this.overlay = true;

        // keyValuePairsObject
        if (this.keyValuePairsObject.length > 0 || this.overlay) {
        var reqData = {
            name: this.editedAiModel.name,
            line_separator: this.editedAiModel.line_separator,
            company_id: this.auth.user.company_id,
        };

        if (this.companyName != "") {
            var id = this.$helpers.getIdByNameFromList(
            this.companies,
            this.companyName
            );

            if (id > 0) {
            reqData.company_id = id;
            }
        }

        var keyValuePairs = {};
        var indexLabel = 1;

        this.keyValuePairsObject.forEach((keyValuePair) => {
            if (keyValuePair.type == "Numbers Only") {
            keyValuePairs["label_" + indexLabel] = [
                this.$helpers.toSnakeCase(keyValuePair.value),
                this.$helpers.toSnakeCase(keyValuePair.type),
                parseInt(keyValuePair.maxLength),
            ];
            } else {
            keyValuePairs["label_" + indexLabel] = [
                this.$helpers.toSnakeCase(keyValuePair.value),
                this.$helpers.toSnakeCase(keyValuePair.type),
            ];
            }

            indexLabel++;
        });

        reqData.key_value_pairs = keyValuePairs;

        const res = await this.$helpers.createApiData(
            "ai-models",
            reqData,
            "Ai Model Created Successfully!"
        );

        if (res.status == 200) {
            this.aiModels.push(res.data);

            var formData = new FormData();

            // files
            formData.append("files[" + 0 + "]", this.image);

            const res2 = await this.$helpers.createApiFormData(
            `ai-models/add-image-to-ai-model/${res.data.id}`,
            formData,
            "Image Uploaded Successfully!"
            );

            if (res2.status == 200) {
            // // setCountDown
            // this.setCountDown(15 * 60);

            // // setTimeLeft
            // this.setTimeLeft(15 * 60 * 1000);

            // // setDialogAiModel
            // this.setDialogAiModel(true);

            // // setAiModelId
            // this.setAiModelId(res.data.id);
            
            this.$router.push({
                name: "DashboardAiModelView",
                params: { id: res.data.id },
            });
            }

            this.close();
        }
        } else {
        this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Provide Atleast One Key Value Pairs",
        });
        }

        this.overlay = false;
    },

    // retrainApiAiModel
    async retrainApiAiModel(id) {
        this.overlay = true;

        const res = await this.$helpers.createApiData(
        `/ai-models/retrain-ai-model/${id}`,
        {},
        "Started Retrain Successfully!"
        );

        if (res.status == 200) {
        let aiModel = this.aiModels.find((aiModel) => aiModel.id == id);

        let index = this.aiModels.indexOf(aiModel);

        Object.assign(this.aiModels[index], res.data);

        // setCountDown
        this.setCountDown(15 * 60);

        // setTimeLeft
        this.setTimeLeft(15 * 60 * 1000);

        // setDialogAiModel
        this.setDialogAiModel(true);

        // setAiModelId
        this.setAiModelId(res.data.id);

        this.dialogRetrain = false;
        }

        this.close();

        this.overlay = false;
    },

    // updateApiAiModel
    async updateApiAiModel() {
        this.overlay = true;

        // keyValuePairsObject
        if (this.keyValuePairsObject.length > 0 || this.overlay) {
        var reqData = {
            id: this.editedAiModel.id,
            name: this.editedAiModel.name,
            line_separator: this.editedAiModel.line_separator,
            company_id: this.auth.user.company_id,
        };

        if (this.companyName != "") {
            var id = this.$helpers.getIdByNameFromList(
            this.companies,
            this.companyName
            );

            if (id > 0) {
            reqData.company_id = id;
            }
        }

        var keyValuePairs = {};
        var indexLabel = 1;

        this.keyValuePairsObject.forEach((keyValuePair) => {
            if (keyValuePair.type == "Numbers Only") {
            keyValuePairs["label_" + indexLabel] = [
                this.$helpers.toSnakeCase(keyValuePair.value),
                this.$helpers.toSnakeCase(keyValuePair.type),
                parseInt(keyValuePair.maxLength),
            ];
            } else {
            keyValuePairs["label_" + indexLabel] = [
                this.$helpers.toSnakeCase(keyValuePair.value),
                this.$helpers.toSnakeCase(keyValuePair.type),
            ];
            }

            indexLabel++;
        });

        reqData.key_value_pairs = keyValuePairs;

        const res = await this.$helpers.updateApiData(
            `ai-models/${this.editedAiModel.id}`,
            reqData,
            "Ai Model Updated Successfully!"
        );

        if (res.status == 200) {
            Object.assign(this.aiModels[this.editedIndex], res.data);

            this.close();
        }
        } else {
        this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Provide Atleast One Key Value Pairs",
        });
        }

        this.overlay = false;
    },

    // deleteApiAiModel
    async deleteApiAiModel() {
        this.overlay = true;

        const res = await this.$helpers.deleteApiData(
        `ai-models/${this.editedAiModel.id}`,
        "Ai Model Deleted Successfully!"
        );

        if (res.status == 200) {
        this.aiModels.splice(this.editedIndex, 1);

        this.closeDelete();
        }

        this.overlay = false;
    },

    // Get Api Companies
    async getApiCompanies() {
        this.overlay = true;

        const res = await this.$helpers.getApiData("companies");

        if (res.status == 200) {
        this.companies = res.data;

        this.companyNamesList = this.$helpers.getNamesFromList(this.companies);
        }

        this.overlay = false;
    },
},
};
</script>

<style lang="scss" scoped>
//
</style>
